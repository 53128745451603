const LanguageCardData = [
	// Compliance
  {
    id: "Doc",
    type: "Compliance",
    link: "/doc",
    languageCode: <path d="M79.486 0H54.514C50.41 0 46.66 1.514 43.769 4H20c-5.514 0-10 4.486-10 10v68c0 5.514 4.486 10 10 10h56c5.514 0 10-4.486 10-10V42.656c5.875-2.533 10-8.377 10-15.17V16.514C96 7.408 88.592 0 79.486 0zM82 82c0 3.309-2.691 6-6 6H20c-3.309 0-6-2.691-6-6V14c0-3.309 2.691-6 6-6h20.391A16.387 16.387 0 0 0 38 16.514v10.972C38 36.592 45.408 44 54.514 44h.455l7.315 7.405a1.998 1.998 0 0 0 2.846 0L72.445 44h7.04c.857 0 1.692-.086 2.515-.212V82zm10-54.514C92 34.386 86.386 40 79.485 40h-7.876c-.535 0-1.047.214-1.423.595l-6.479 6.559-6.479-6.559A1.998 1.998 0 0 0 55.805 40h-1.291C47.614 40 42 34.386 42 27.486V16.514C42 9.614 47.614 4 54.514 4h24.973C86.387 4 92 9.614 92 16.514v10.972zM66.5 8C64.57 8 63 9.57 63 11.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5S68.43 8 66.5 8zm0 4a.5.5 0 1 1 .002-1.002A.5.5 0 0 1 66.5 12zM70 22.5v10c0 1.93-1.57 3.5-3.5 3.5S63 34.43 63 32.5v-10c0-1.93 1.57-3.5 3.5-3.5s3.5 1.57 3.5 3.5zM24 18a2 2 0 0 1 2-2h6a2 2 0 0 1 0 4h-6a2 2 0 0 1-2-2zm0 12a2 2 0 0 1 2-2h6a2 2 0 0 1 0 4h-6a2 2 0 0 1-2-2zm48 48a2 2 0 0 1-2 2H26a2 2 0 0 1 0-4h44a2 2 0 0 1 2 2zm0-12a2 2 0 0 1-2 2H26a2 2 0 0 1 0-4h44a2 2 0 0 1 2 2zM24 54a2 2 0 0 1 2-2h22a2 2 0 0 1 0 4H26a2 2 0 0 1-2-2zm0-12a2 2 0 0 1 2-2h11a2 2 0 0 1 0 4H26a2 2 0 0 1-2-2z"/>,
    language: "Declaration of conformity",
  },
	{
    id: "Licenses",
    type: "Compliance",
    link: "/licenses",
    languageCode: <path d="M79.486 0H54.514C50.41 0 46.66 1.514 43.769 4H20c-5.514 0-10 4.486-10 10v68c0 5.514 4.486 10 10 10h56c5.514 0 10-4.486 10-10V42.656c5.875-2.533 10-8.377 10-15.17V16.514C96 7.408 88.592 0 79.486 0zM82 82c0 3.309-2.691 6-6 6H20c-3.309 0-6-2.691-6-6V14c0-3.309 2.691-6 6-6h20.391A16.387 16.387 0 0 0 38 16.514v10.972C38 36.592 45.408 44 54.514 44h.455l7.315 7.405a1.998 1.998 0 0 0 2.846 0L72.445 44h7.04c.857 0 1.692-.086 2.515-.212V82zm10-54.514C92 34.386 86.386 40 79.485 40h-7.876c-.535 0-1.047.214-1.423.595l-6.479 6.559-6.479-6.559A1.998 1.998 0 0 0 55.805 40h-1.291C47.614 40 42 34.386 42 27.486V16.514C42 9.614 47.614 4 54.514 4h24.973C86.387 4 92 9.614 92 16.514v10.972zM66.5 8C64.57 8 63 9.57 63 11.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5S68.43 8 66.5 8zm0 4a.5.5 0 1 1 .002-1.002A.5.5 0 0 1 66.5 12zM70 22.5v10c0 1.93-1.57 3.5-3.5 3.5S63 34.43 63 32.5v-10c0-1.93 1.57-3.5 3.5-3.5s3.5 1.57 3.5 3.5zM24 18a2 2 0 0 1 2-2h6a2 2 0 0 1 0 4h-6a2 2 0 0 1-2-2zm0 12a2 2 0 0 1 2-2h6a2 2 0 0 1 0 4h-6a2 2 0 0 1-2-2zm48 48a2 2 0 0 1-2 2H26a2 2 0 0 1 0-4h44a2 2 0 0 1 2 2zm0-12a2 2 0 0 1-2 2H26a2 2 0 0 1 0-4h44a2 2 0 0 1 2 2zM24 54a2 2 0 0 1 2-2h22a2 2 0 0 1 0 4H26a2 2 0 0 1-2-2zm0-12a2 2 0 0 1 2-2h11a2 2 0 0 1 0 4H26a2 2 0 0 1-2-2z"/>,
    language: "Third party licenses",
  },
	// Doc
	{
		id: "DocEN",
		type: "Declaration of conformity",
		link: "/doc/en",
		languageCode: "EN",
		language: "English",
	},
	{
		id: "DocFI",
		type: "Declaration of conformity",
		link: "/doc/fi",
		languageCode: "FI",
		language: "Finnish",
	},
	{
		id: "DocFR",
		type: "Declaration of conformity",
		link: "/doc/fr",
		languageCode: "FR",
		language: "French",
	},
	{
		id: "DocDE",
		type: "Declaration of conformity",
		link: "/doc/de",
		languageCode: "DE",
		language: "German",
	},
	{
		id: "DocPL",
		type: "Declaration of conformity",
		link: "/doc/pl",
		languageCode: "PL",
		language: "Polish",
	},
	{
		id: "DocES",
		type: "Declaration of conformity",
		link: "/doc/es",
		languageCode: "ES",
		language: "Spanish",
	},
	// Manuals
	{
		id: "ManualsEN",
		type: "Manual",
		link: "/manuals/en",
		languageCode: "EN",
		language: "English",
	},
	{
		id: "ManualsSV",
		type: "Manual",
		link: "/manuals/sv",
		languageCode: "SV",
		language: "Swedish",
	},
	{
		id: "ManualsNL",
		type: "Manual",
		link: "/manuals/nl",
		languageCode: "NL",
		language: "Dutch",
	},
	{
		id: "ManualsFI",
		type: "Manual",
		link: "/manuals/fi",
		languageCode: "FI",
		language: "Finnish",
	},
	{
		id: "ManualsFR",
		type: "Manual",
		link: "/manuals/fr",
		languageCode: "FR",
		language: "French",
	},
	{
		id: "ManualsDE",
		type: "Manual",
		link: "/manuals/de",
		languageCode: "DE",
		language: "German",
	},
	{
		id: "ManualsIT",
		type: "Manual",
		link: "/manuals/it",
		languageCode: "IT",
		language: "Italian",
	},
	{
		id: "ManualsNB",
		type: "Manual",
		link: "/manuals/nb",
		languageCode: "NB",
		language: "Norwegian",
	},
	{
		id: "ManualsPL",
		type: "Manual",
		link: "/manuals/pl",
		languageCode: "PL",
		language: "Polish",
	},
	{
		id: "ManualsES",
		type: "Manual",
		link: "/manuals/es",
		languageCode: "ES",
		language: "Spanish",
	},
	// Product sheets
	{
		id: "ProductSheetsEN",
		type: "Product sheet",
		link: "/product-sheets/en",
		languageCode: "EN",
		language: "English",
	},
	{
		id: "ProductSheetsSV",
		type: "Product sheet",
		link: "/product-sheets/sv",
		languageCode: "SV",
		language: "Swedish",
	},
	{
		id: "ProductSheetsNL",
		type: "Product sheet",
		link: "/product-sheets/nl",
		languageCode: "NL",
		language: "Dutch",
	},
	{
		id: "ProductSheetsFI",
		type: "Product sheet",
		link: "/product-sheets/fi",
		languageCode: "FI",
		language: "Finnish",
	},
	{
		id: "ProductSheetsFR",
		type: "Product sheet",
		link: "/product-sheets/fr",
		languageCode: "FR",
		language: "French",
	},
	{
		id: "ProductSheetsDE",
		type: "Product sheet",
		link: "/product-sheets/de",
		languageCode: "DE",
		language: "German",
	},
	{
		id: "ProductSheetsIT",
		type: "Product sheet",
		link: "/product-sheets/it",
		languageCode: "IT",
		language: "Italian",
	},
	{
		id: "ProductSheetsNB",
		type: "Product sheet",
		link: "/product-sheets/nb",
		languageCode: "NB",
		language: "Norwegian",
	},
	{
		id: "ProductSheetsPL",
		type: "Product sheet",
		link: "/product-sheets/pl",
		languageCode: "PL",
		language: "Polish",
	},
	{
		id: "ProductSheetsES",
		type: "Product sheet",
		link: "/product-sheets/es",
		languageCode: "ES",
		language: "Spanish",
	},
	{
		id: "ProductSheetsSVFI",
		type: "Product sheet",
		link: "/product-sheets/sv-fi",
		languageCode: "SV",
		language: "Swedish (FI)",
	},
	// Sales material
	{
		id: "SalesMaterialEN",
		type: "Sales material",
		link: "/sales-material/en",
		languageCode: "EN",
		language: "English",
	},
	{
		id: "SalesMaterialSV",
		type: "Sales material",
		link: "/sales-material/sv",
		languageCode: "SV",
		language: "Swedish",
	},
	{
		id: "SalesMaterialNB",
		type: "Sales material",
		link: "/sales-material/nb",
		languageCode: "NB",
		language: "Norwegian",
	},
	{
		id: "SalesMaterialNL",
		type: "Sales material",
		link: "/sales-material/nl",
		languageCode: "NL",
		language: "Dutch",
	},
	{
		id: "SalesMaterialFI",
		type: "Sales material",
		link: "/sales-material/fi",
		languageCode: "FI",
		language: "Finnish",
	},
	{
		id: "SalesMaterialDE",
		type: "Sales material",
		link: "/sales-material/de",
		languageCode: "DE",
		language: "German",
	},
	{
		id: "SalesMaterialDECH",
		type: "Sales material",
		link: "/sales-material/de-ch",
		languageCode: "DE",
		language: "German (CH)",
	},
	{
		id: "SalesMaterialFR",
		type: "Sales material",
		link: "/sales-material/fr",
		languageCode: "FR",
		language: "French",
	},
	{
		id: "SalesMaterialES",
		type: "Sales material",
		link: "/sales-material/es",
		languageCode: "ES",
		language: "Spanish",
	},
	// Product images
	{
		id: "ProductImages-dim-01",
		type: "Product image",
		link: "/product-images/dim-01",
		languageCode: <path d="M74 4H22C12.1 4 4 12.1 4 22v52c0 9.941 8.059 18 18 18h52c9.9 0 18-8.1 18-18V22c0-9.941-8.059-18-18-18zm1 83.949c-.332.024-.662.051-1 .051H22c-.338 0-.668-.027-1-.051V74h54v13.949zM88 74c0 5.956-3.746 11.042-9 13.061V74c0-2.2-1.8-4-4-4H21c-2.2 0-4 1.8-4 4v13.061C11.746 85.042 8 79.956 8 74V22c0-7.72 6.28-14 14-14h52c7.72 0 14 6.28 14 14v52z"/>,
		language: "DIM-01",
	},
	{
		id: "ProductImages-dim-01-2p",
		type: "Product image",
		link: "/product-images/dim-01-2p",
		languageCode: <path d="M74 4H22C12.1 4 4 12.1 4 22v52c0 9.941 8.059 18 18 18h52c9.9 0 18-8.1 18-18V22c0-9.941-8.059-18-18-18zM36 8h24v14H36V8zm39 79.949c-.332.024-.662.051-1 .051H22c-.338 0-.668-.027-1-.051V74h54v13.949zM88 74c0 5.956-3.746 11.042-9 13.061V74c0-2.2-1.8-4-4-4H21c-2.2 0-4 1.8-4 4v13.061C11.746 85.042 8 79.956 8 74V22c0-7.72 6.28-14 14-14h10v14c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4V8h10c7.72 0 14 6.28 14 14v52z"/>,
		language: "DIM-01-2P",
	},
	{
		id: "ProductImages-dim-02",
		type: "Product image",
		link: "/product-images/dim-02",
		languageCode: <path d="M74 4H22C12.1 4 4 12.1 4 22v52c0 9.941 8.059 18 18 18h52c9.9 0 18-8.1 18-18V22c0-9.941-8.059-18-18-18zM36 8h24v14H36V8zm39 79.949c-.332.024-.662.051-1 .051H22c-.338 0-.668-.027-1-.051V74h54v13.949zM88 74c0 5.956-3.746 11.042-9 13.061V74c0-2.2-1.8-4-4-4H21c-2.2 0-4 1.8-4 4v13.061C11.746 85.042 8 79.956 8 74V22c0-7.72 6.28-14 14-14h10v14c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4V8h10c7.72 0 14 6.28 14 14v52z"/>,
		language: "DIM-02",
	},
	{
		id: "ProductImages-bat-01",
		type: "Product image",
		link: "/product-images/bat-01",
		languageCode: <path d="M90 24H76c-1 0-1-.7-1-1v-7c0-2-1.3-5-5-5H26c-3.7 0-5 3-5 5v7c0 .3 0 1-1 1H6a4 4 0 0 0-4 4v40c0 2 1.4 4 4 4h13.5c.3.6.8 1.1 1.5 1.5V79c0 3.5 5.3 6 8 6 3.2 0 6.6-2 8.8-3.2l.4-.2C38.7 83 39.7 85 42 85h12c2.3 0 3.3-2 3.8-3.4l.4.2C60.4 83 63.8 85 67 85c2.7 0 8-2.5 8-6v-5.5c.7-.4 1.1-.9 1.5-1.5H90c2.6 0 4-2 4-4V28c0-2-1.4-4-4-4ZM6 68V28h4v6.5c-1.1.6-2 1.8-2 3.5v20c0 1.7.9 2.9 2 3.5V68H6Zm65 11c-.3.6-2.7 2-4 2-2.1 0-5-1.6-6.9-2.7C58.6 77.4 58 77 57 77c-1.8 0-2.5 1.8-2.8 2.8-.1.3-.3 1-.5 1.2H42.3l-.5-1.2c-.3-1-1-2.8-2.8-2.8-.9 0-1.6.4-3.1 1.3-2 1-4.8 2.7-6.9 2.7-1.3 0-3.7-1.4-4-2v-7h2.8l.4.9A2 2 0 0 0 30 74h12a2 2 0 0 0 2-2h8c0 1.1.9 2 2 2h12a2 2 0 0 0 1.8-1.1l.4-.9H71v7Zm4-11h-8a2 2 0 0 0-1.8 1.1l-.4.9H56a2 2 0 0 0-2-2H42a2 2 0 0 0-2 2h-8.8l-.4-.9A2 2 0 0 0 29 68H14v-8a2 2 0 0 0-2-2V38a2 2 0 0 0 2-2v-8h6c2 0 5-1.3 5-5v-7c0-1 .7-1 1-1h44c.3 0 1 0 1 1v7c0 3.7 3 5 5 5h6v8c0 1.1.8 2 2 2v20a2 2 0 0 0-2 2v8h-7Zm15 0h-4v-6.5c1.1-.6 2-1.8 2-3.5V38c0-1.7-.9-2.9-2-3.5V28h4v40ZM28 20c0-1.1.9-2 2-2h5a2 2 0 0 1 0 4h-5a2 2 0 0 1-2-2Zm31 0c0-1.1.9-2 2-2h5a2 2 0 0 1 0 4h-5a2 2 0 0 1-2-2Zm20 38V38c0-2.6-2-4-4-4H56a9 9 0 0 0-16 0H21a4 4 0 0 0-4 4v20c0 2.6 2 4 4 4h54c2 0 4-1.4 4-4ZM48 33a5 5 0 1 1 0 10 5 5 0 0 1 0-10Zm27 25H21V38h18a9 9 0 0 0 18 0h18v20Zm-3-11v6a2 2 0 0 1-4 0v-6a2 2 0 0 1 4 0Z"/>,
		language: "BAT-01",
	},
	{
		id: "ProductImages-ctr-01",
		type: "Product image",
		link: "/product-images/ctr-01",
		languageCode: <path d="M74 4H22C12.1 4 4 12.1 4 22v52c0 9.941 8.059 18 18 18h52c9.9 0 18-8.1 18-18V22c0-9.941-8.059-18-18-18zM36 8h24v14H36V8zm39 79.949c-.332.024-.662.051-1 .051H22c-.338 0-.668-.027-1-.051V74h54v13.949zM88 74c0 5.956-3.746 11.042-9 13.061V74c0-2.2-1.8-4-4-4H21c-2.2 0-4 1.8-4 4v13.061C11.746 85.042 8 79.956 8 74V22c0-7.72 6.28-14 14-14h10v14c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4V8h10c7.72 0 14 6.28 14 14v52z"/>,
		language: "CTR-01",
	},
	{
		id: "ProductImages-dal-01",
		type: "Product image",
		link: "/product-images/dal-01",
		languageCode: <path d="M74 4H22C12.1 4 4 12.1 4 22v52c0 9.941 8.059 18 18 18h52c9.9 0 18-8.1 18-18V22c0-9.941-8.059-18-18-18zM36 8h24v14H36V8zm39 79.949c-.332.024-.662.051-1 .051H22c-.338 0-.668-.027-1-.051V74h54v13.949zM88 74c0 5.956-3.746 11.042-9 13.061V74c0-2.2-1.8-4-4-4H21c-2.2 0-4 1.8-4 4v13.061C11.746 85.042 8 79.956 8 74V22c0-7.72 6.28-14 14-14h10v14c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4V8h10c7.72 0 14 6.28 14 14v52z"/>,
		language: "DAL-01",
	},
	{
		id: "ProductImages-jal-01",
		type: "Product image",
		link: "/product-images/jal-01",
		languageCode: <path d="M74 4H22C12.1 4 4 12.1 4 22v52c0 9.941 8.059 18 18 18h52c9.9 0 18-8.1 18-18V22c0-9.941-8.059-18-18-18zM36 8h24v14H36V8zm39 79.949c-.332.024-.662.051-1 .051H22c-.338 0-.668-.027-1-.051V74h54v13.949zM88 74c0 5.956-3.746 11.042-9 13.061V74c0-2.2-1.8-4-4-4H21c-2.2 0-4 1.8-4 4v13.061C11.746 85.042 8 79.956 8 74V22c0-7.72 6.28-14 14-14h10v14c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4V8h10c7.72 0 14 6.28 14 14v52z"/>,
		language: "JAL-01",
	},
	{
		id: "ProductImages-dwn-01",
		type: "Product image",
		link: "/product-images/dwn-01",
		languageCode: <path d="m78.957 41.248-.534-8.55 12.328 9.863a1.993 1.993 0 0 0 2.81-.312 2 2 0 0 0-.312-2.811l-15-12c-.051-.041-.108-.07-.162-.105l-.091-1.459a1.694 1.694 0 0 0-.017-.158c-.563-3.947-8.513-5.707-16.979-6.455V16a2 2 0 0 0-1.367-1.897c-6.414-2.138-16.852-2.138-23.266 0A2 2 0 0 0 35 16v3.262c-8.466.748-16.416 2.508-16.98 6.455a2.061 2.061 0 0 0-.016.158l-.091 1.458c-.054.035-.111.064-.162.105l-15 12a2 2 0 0 0 2.498 3.124L17.577 32.7l-.534 8.549C7.801 45.638 2 51.966 2 59c0 13.255 20.595 24 46 24s46-10.745 46-24c0-7.034-5.801-13.362-15.043-17.752zM39 17.497c5.219-1.34 12.781-1.34 18 0v1.482c-3.205-.174-6.33-.23-9-.23s-5.795.056-9 .23v-1.482zm-17.02 8.876C23.217 24.831 32.771 22.75 48 22.75s24.783 2.081 26.02 3.623l.821 13.142C67.285 36.677 58.021 35 48 35s-19.285 1.677-26.841 4.515l.821-13.142zm56.697 46.051C70.549 76.665 59.655 79 48 79s-22.549-2.335-30.677-6.576C10.127 68.67 6 63.777 6 59c0-4.777 4.127-9.67 11.323-13.424C25.451 41.335 36.345 39 48 39s22.549 2.335 30.677 6.576C85.873 49.33 90 54.223 90 59c0 4.777-4.127 9.67-11.323 13.424zM48 44c-15.464 0-28 6.716-28 15 0 8.284 12.536 15 28 15s28-6.716 28-15c0-8.284-12.536-15-28-15zm16.95 7.455c-.518 4.738-8.965 7.295-16.95 7.295s-16.432-2.557-16.95-7.295C35.618 49.26 41.709 48 48 48s12.382 1.26 16.95 3.455zm.96 14.626C61.261 68.571 54.733 70 48 70s-13.261-1.429-17.91-3.919C26.22 64.007 24 61.427 24 59c0-1.798 1.225-3.68 3.431-5.379.899 2.661 3.189 4.92 6.676 6.51C37.809 61.82 42.742 62.75 48 62.75c12.342 0 18.865-4.274 20.543-9.151C70.767 55.303 72 57.194 72 59c0 2.427-2.22 5.007-6.09 7.081z"/>,
		language: "DWN-01",
	},
	{
		id: "ProductImages-dwn-02",
		type: "Product image",
		link: "/product-images/dwn-02",
		languageCode: <path d="m78.957 41.248-.534-8.55 12.328 9.863a1.993 1.993 0 0 0 2.81-.312 2 2 0 0 0-.312-2.811l-15-12c-.051-.041-.108-.07-.162-.105l-.091-1.459a1.694 1.694 0 0 0-.017-.158c-.563-3.947-8.513-5.707-16.979-6.455V16a2 2 0 0 0-1.367-1.897c-6.414-2.138-16.852-2.138-23.266 0A2 2 0 0 0 35 16v3.262c-8.466.748-16.416 2.508-16.98 6.455a2.061 2.061 0 0 0-.016.158l-.091 1.458c-.054.035-.111.064-.162.105l-15 12a2 2 0 0 0 2.498 3.124L17.577 32.7l-.534 8.549C7.801 45.638 2 51.966 2 59c0 13.255 20.595 24 46 24s46-10.745 46-24c0-7.034-5.801-13.362-15.043-17.752zM39 17.497c5.219-1.34 12.781-1.34 18 0v1.482c-3.205-.174-6.33-.23-9-.23s-5.795.056-9 .23v-1.482zm-17.02 8.876C23.217 24.831 32.771 22.75 48 22.75s24.783 2.081 26.02 3.623l.821 13.142C67.285 36.677 58.021 35 48 35s-19.285 1.677-26.841 4.515l.821-13.142zm56.697 46.051C70.549 76.665 59.655 79 48 79s-22.549-2.335-30.677-6.576C10.127 68.67 6 63.777 6 59c0-4.777 4.127-9.67 11.323-13.424C25.451 41.335 36.345 39 48 39s22.549 2.335 30.677 6.576C85.873 49.33 90 54.223 90 59c0 4.777-4.127 9.67-11.323 13.424zM48 44c-15.464 0-28 6.716-28 15 0 8.284 12.536 15 28 15s28-6.716 28-15c0-8.284-12.536-15-28-15zm16.95 7.455c-.518 4.738-8.965 7.295-16.95 7.295s-16.432-2.557-16.95-7.295C35.618 49.26 41.709 48 48 48s12.382 1.26 16.95 3.455zm.96 14.626C61.261 68.571 54.733 70 48 70s-13.261-1.429-17.91-3.919C26.22 64.007 24 61.427 24 59c0-1.798 1.225-3.68 3.431-5.379.899 2.661 3.189 4.92 6.676 6.51C37.809 61.82 42.742 62.75 48 62.75c12.342 0 18.865-4.274 20.543-9.151C70.767 55.303 72 57.194 72 59c0 2.427-2.22 5.007-6.09 7.081z"/>,
		language: "DWN-02",
	},
	{
		id: "ProductImages-ext-01",
		type: "Product image",
		link: "/product-images/ext-01",
		languageCode: <path d="M74 4H22C12.1 4 4 12.1 4 22v52c0 9.941 8.059 18 18 18h52c9.9 0 18-8.1 18-18V22c0-9.941-8.059-18-18-18zM36 8h24v14H36V8zm39 79.949c-.332.024-.662.051-1 .051H22c-.338 0-.668-.027-1-.051V74h54v13.949zM88 74c0 5.956-3.746 11.042-9 13.061V74c0-2.2-1.8-4-4-4H21c-2.2 0-4 1.8-4 4v13.061C11.746 85.042 8 79.956 8 74V22c0-7.72 6.28-14 14-14h10v14c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4V8h10c7.72 0 14 6.28 14 14v52z"/>,
		language: "EXT-01",
	},
	{
		id: "ProductImages-gwy-01",
		type: "Product image",
		link: "/product-images/gwy-01",
		languageCode: <path d="M23 27.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0zM94 66c0 6.761-5.626 14-14 14H16C9.373 80 4 74.627 4 68V28c0-6.6 5.4-12 12-12h40c6.627 0 12 5.373 12 12v5.121C77.262 34.193 85 42.552 85 52v.483c5.23 1.861 9 7.38 9 13.517zM16 76h15.567C28.737 73.114 27 68.885 27 64c0-5.278 2.826-10.505 7-13.162V50c0-5.771 4.588-12 12-12 1.918 0 3.195.068 5.388 1.277 2.63-3.303 7.455-5.677 12.612-6.176V28c0-4.411-3.589-8-8-8H16c-4.411 0-8 3.589-8 8v40c0 4.411 3.589 8 8 8zm74-10c0-4.88-3.178-9.196-7.393-10.039A2 2 0 0 1 81 54v-2c0-7.851-7.149-15-15-15-5.194 0-10.559 2.589-12.211 5.895a2 2 0 0 1-2.898.769C48.395 42 47.791 42 46 42c-4.941 0-8 4.152-8 8v2a2 2 0 0 1-1.105 1.789C33.534 55.469 31 59.858 31 64c0 6.953 4.206 12 10 12h39c5.981 0 10-5.171 10-10z"/>,
		language: "GWY-01",
	},
	{
		id: "ProductImages-led-10",
		type: "Product image",
		link: "/product-images/led-10",
		languageCode: <path d="M74 4H22C12.1 4 4 12.1 4 22v52c0 9.941 8.059 18 18 18h52c9.9 0 18-8.1 18-18V22c0-9.941-8.059-18-18-18zM36 8h24v14H36V8zm39 79.949c-.332.024-.662.051-1 .051H22c-.338 0-.668-.027-1-.051V74h54v13.949zM88 74c0 5.956-3.746 11.042-9 13.061V74c0-2.2-1.8-4-4-4H21c-2.2 0-4 1.8-4 4v13.061C11.746 85.042 8 79.956 8 74V22c0-7.72 6.28-14 14-14h10v14c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4V8h10c7.72 0 14 6.28 14 14v52z"/>,
		language: "LED-10",
	},
	{
		id: "ProductImages-led-75",
		type: "Product image",
		link: "/product-images/led-75",
		languageCode: <path d="M87.558 23.012c-.316-.316-1.257-1.257-11.932-8.053-1.797-1.3-3.948-1.959-6.398-1.959-3.519 0-6.558 1.41-7.073 1.662L12.388 38.337C10.492 39.115 5 41.792 5 46.658c0 .117.013.228.02.342H5v15c0 3.016.388 8.229 2.951 9.705l15.916 10.943.104.066c1.875 1.126 3.738 1.558 5.536 1.558 3.543 0 6.834-1.677 9.477-3.022l.969-.491 47.942-25.97C91 53.236 91 50.048 91 49V30c0-3.546-2.239-5.785-3.442-6.988zM14 42l49.899-23.738S66.423 17 69.228 17c1.402 0 2.875.315 4.137 1.262 0 0 10.412 6.626 11.358 7.572S87 28.107 87 30c0 3-2 4-2 4L36.45 58.963c-2.435 1.044-4.487 1.448-6.249 1.448-3.032 0-5.206-1.197-7.003-2.395L12 50s-3-1.684-3-3.342C9 44 14 42 14 42zm12.079 37.314L25 78.573v-8.179c0-3.071-.95-4.763-2.674-4.763-.786 0-1.825.485-2.17 1.853-.105.416-.156.936-.156 1.587v6.064l-3-2.062V66.03c0-3.35-.924-5.119-2.674-5.119-.845 0-1.861.512-2.183 1.949-.096.433-.143.981-.143 1.677v5.098l-1.867-1.283-.129-.086C9.477 67.579 9.002 64.648 9 62v-9.16c.304.207.589.386.835.53l11.035 7.9.11.076c2.105 1.404 5.03 3.003 9.021 3.056v15.847c-1.316.075-2.626-.17-3.922-.935zm59.969-28.073-47.942 25.97-.938.475c-1.048.534-2.106 1.07-3.168 1.517V64h-.137c1.342-.293 2.722-.743 4.162-1.36.086-.037.17-.077.253-.119l48.55-24.963c.056-.028.114-.071.171-.102V49c.001 1.195-.133 1.831-.951 2.241z"/>,
		language: "LED-75",
	},
	{
		id: "ProductImages-lst-01",
		type: "Product image",
		link: "/product-images/lst-01",
		languageCode: <path d="M28 92a2 2 0 0 1-2-2V6a2 2 0 0 1 4 0v84a2 2 0 0 1-2 2zm42-2V6a2 2 0 0 0-4 0v84a2 2 0 0 0 4 0zM57 32V22c0-2.206-1.794-4-4-4H43c-2.206 0-4 1.794-4 4v10c0 2.206 1.794 4 4 4h10c2.206 0 4-1.794 4-4zm-4-10 .003 10H43V22h10zM40 11V5a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm20 0V5a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm-10 0V5a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zM40 53V43a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm20 0V43a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm-10 0V43a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zM40 91v-6a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm20 0v-6a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm-10 0v-6a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1zm7-17V64c0-2.206-1.794-4-4-4H43c-2.206 0-4 1.794-4 4v10c0 2.206 1.794 4 4 4h10c2.206 0 4-1.794 4-4zm-4-10 .003 10H43V64h10z"/>,
		language: "LST-01",
	},
	{
		id: "ProductImages-mnt-01",
		type: "Product image",
		link: "/product-images/mnt-01",
		languageCode: <path d="M90 24H76.033C75 23.962 75 23.336 75 23v-7c0-2.01-1.332-5-5-5H26c-3.668 0-5 2.99-5 5v7c0 .336 0 .962-1 1H6c-2.598 0-4 2.061-4 4v40c0 1.939 1.402 4 4 4h13.513A3.807 3.807 0 0 0 21 73.508V79c0 3.466 5.261 6 8 6 3.198 0 6.596-1.941 8.844-3.227l.359-.205C38.714 82.953 39.66 85 42 85h12c2.34 0 3.286-2.047 3.797-3.432l.359.205C60.404 83.058 63.802 85 67 85c2.738 0 8-2.534 8-6v-5.492A3.798 3.798 0 0 0 76.486 72H90c2.598 0 4-2.061 4-4V28c0-1.939-1.402-4-4-4Zm-6 29H68c-2.897 0-5-2.103-5-5s2.103-5 5-5h16v10ZM6 68V28.057c0-.017.003-.037.007-.057H10v6.514c-1.136.623-2 1.804-2 3.486v20c0 1.683.864 2.863 2 3.486V68H6Zm65 10.93c-.31.716-2.734 2.07-4 2.07-2.136 0-4.979-1.624-6.859-2.699C58.625 77.435 57.865 77 57 77c-1.828 0-2.453 1.768-2.827 2.823-.115.327-.312.884-.479 1.177H42.305c-.167-.293-.363-.85-.479-1.177-.374-1.056-.999-2.823-2.827-2.823-.864 0-1.625.435-3.141 1.301C33.977 79.376 31.134 81 28.999 81c-1.266 0-3.691-1.354-4-2.07V72h2.764l.447.895A2 2 0 0 0 29.999 74h12a2 2 0 0 0 2-2h8a2 2 0 0 0 2 2h12a2 2 0 0 0 1.789-1.105l.447-.895h2.764v6.93ZM75 68h-8a2 2 0 0 0-1.789 1.105l-.447.895H56a2 2 0 0 0-2-2H42a2 2 0 0 0-2 2h-8.764l-.447-.895A2 2 0 0 0 29 68H14v-8c0-1.104-.839-1.999-1.943-1.999-.017 0-.037-.004-.057-.008V38.001a2 2 0 0 0 2-2v-8h6c2.01 0 5-1.332 5-5v-6.977c.042-1.023.665-1.023 1-1.023h44c.336 0 .962 0 1 1v7c0 3.668 2.99 5 5 5h6v8c0 1.104.839 1.999 1.943 1.999.017 0 .036.004.057.008v.993H68c-5.131 0-9 3.869-9 9s3.869 9 9 9h16V58a2 2 0 0 0-2 2.001v8h-7Zm14.993 0H86v-6.514c1.136-.623 2-1.804 2-3.486V38c0-1.683-.864-2.863-2-3.486V28h4v39.943a.315.315 0 0 1-.007.057ZM37 20a2 2 0 0 1-2 2h-5a2 2 0 0 1 0-4h5a2 2 0 0 1 2 2Zm22 0a2 2 0 0 1 2-2h5a2 2 0 0 1 0 4h-5a2 2 0 0 1-2-2ZM26 34c-4.962 0-9 4.037-9 9v10c0 4.963 4.038 9 9 9s9-4.037 9-9V43c0-4.963-4.038-9-9-9Zm5 19c0 2.757-2.243 5-5 5s-5-2.243-5-5V43c0-2.757 2.243-5 5-5s5 2.243 5 5v10Z"/>,
		language: "MNT-01",
	},
	{
		id: "ProductImages-mnt-02",
		type: "Product image",
		link: "/product-images/mnt-02",
		languageCode: <path d="M70.895 5.211a2.02 2.02 0 0 0-.665-.198C67.557 4.704 44.056 2 42.001 2c-2.756 0-15.807 5.667-16.895 6.211-2.451 1.226-3.105 3.587-3.105 4.789v67c0 .659.123 6.482 4.368 7.897.087.029.177.053.268.069l26.944 4.989c.441.106 2.186.492 4.245.492 1.227 0 2.565-.137 3.808-.551a4.86 4.86 0 0 0 .11-.04c5.319-2.129 10.455-7.228 10.671-7.443.162-.162 1.586-1.665 1.586-4.414V10c0-1.202-.654-3.563-3.105-4.789Zm-3.388 3.516c-1.786 1.028-4.253 2.41-6.401 3.484-2.753 1.378-5.56 1.051-7.07.875-.368-.042-.67-.073-.894-.083l-23.185-2.576C34.115 8.668 40.685 6.096 42 6c1.274 0 15.47 1.577 25.507 2.727ZM54.364 89.033l-26.828-4.968c-1.131-.503-1.53-2.982-1.537-4.065V14.012l26.779 2.976c.058.006.156.011.214.012.136.003.332.031.58.06.688.08 1.661.193 2.816.193.197 0 .403-.005.61-.013v72.176a15.41 15.41 0 0 1-2.636-.382Zm15.223-6.448c-.045.044-4.28 4.237-8.587 6.232v-72.26a13.33 13.33 0 0 0 1.895-.768c2.467-1.234 5.262-2.814 7.105-3.882V81c0 1.053-.421 1.586-.415 1.586h.002Z"/>,
		language: "MNT-02",
	},
	{
		id: "ProductImages-out-01",
		type: "Product image",
		link: "/product-images/out-01",
		languageCode: <path d="M76.407 33.267c-.058-1.654-.124-3.46-.197-5.479L76 22C76 11.293 58.924 6.5 42 6.5S8 11.293 8 22v56c0 4.07 3.861 7.181 11.477 9.243C25.446 88.859 33.445 89.75 42 89.75c15.618 0 33.838-3.053 33.993-11.641.001-.016.006-.032.006-.048l.192-6.279C83.947 70.04 88 68.775 88 64V41c0-4.731-3.977-6.016-11.593-7.733zM42 10.5c8.031 0 15.794 1.184 21.298 3.248 3.97 1.489 8.702 4.129 8.703 8.324l.212 5.861c.716 19.75.716 19.75-.001 43.09l-.022.732C66.002 67.603 53.318 66 42 66c-11.204 0-23.746 1.572-30 5.63V22c0-7.47 15.457-11.5 30-11.5zm9.881 59.938c-.683 1.407-4.543 2.812-9.881 2.812-5.362 0-9.234-1.418-9.89-2.832C35.124 70.151 38.429 70 42 70c3.434 0 6.754.155 9.881.438zM42 85.75c-18.53 0-30-4.022-30-7.75v-.25c0-2.674 5.903-5.499 16.08-6.883.269 1.484 1.346 3.535 4.945 4.935 2.401.934 5.589 1.448 8.975 1.448 9.428 0 13.296-3.251 13.903-6.361 2.776.38 5.337.873 7.575 1.479C68.813 73.813 72 75.825 72 77.75c0 .018.005.034.005.051l-.004.138.002.03c0 .011-.003.021-.003.031 0 3.728-11.47 7.75-30 7.75zM84 64c0 1.193-.373 1.938-7.683 3.648.486-15.93.563-19.829.23-30.242C83.626 39.078 84 39.82 84 41v23z"/>,
		language: "OUT-01",
	},
	{
		id: "ProductImages-rel-01-2p",
		type: "Product image",
		link: "/product-images/rel-01-2p",
		languageCode: <path d="M74 4H22C12.1 4 4 12.1 4 22v52c0 9.941 8.059 18 18 18h52c9.9 0 18-8.1 18-18V22c0-9.941-8.059-18-18-18zM36 8h24v14H36V8zm39 79.949c-.332.024-.662.051-1 .051H22c-.338 0-.668-.027-1-.051V74h54v13.949zM88 74c0 5.956-3.746 11.042-9 13.061V74c0-2.2-1.8-4-4-4H21c-2.2 0-4 1.8-4 4v13.061C11.746 85.042 8 79.956 8 74V22c0-7.72 6.28-14 14-14h10v14c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4V8h10c7.72 0 14 6.28 14 14v52z"/>,
		language: "REL-01-2P",
	},
	{
		id: "ProductImages-rel-02",
		type: "Product image",
		link: "/product-images/rel-02",
		languageCode: <path d="M74 4H22C12.1 4 4 12.1 4 22v52c0 9.941 8.059 18 18 18h52c9.9 0 18-8.1 18-18V22c0-9.941-8.059-18-18-18zM36 8h24v14H36V8zm39 79.949c-.332.024-.662.051-1 .051H22c-.338 0-.668-.027-1-.051V74h54v13.949zM88 74c0 5.956-3.746 11.042-9 13.061V74c0-2.2-1.8-4-4-4H21c-2.2 0-4 1.8-4 4v13.061C11.746 85.042 8 79.956 8 74V22c0-7.72 6.28-14 14-14h10v14c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4V8h10c7.72 0 14 6.28 14 14v52z"/>,
		language: "REL-02",
	},
	{
		id: "ProductImages-rtr-01",
		type: "Product image",
		link: "/product-images/rtr-01",
		languageCode: <path d="M84 4H12c-4.4 0-8 3.6-8 8v72a8 8 0 0 0 8 8h72c4.4 0 8-3.6 8-8V12a8 8 0 0 0-8-8zm4 80c0 2.206-1.794 4-4 4H12c-2.206 0-4-1.794-4-4V12c0-2.206 1.794-4 4-4h72c2.206 0 4 1.794 4 4v72zM72 16H24c-4.4 0-8 3.6-8 8v48a8 8 0 0 0 8 8h48c4.4 0 8-3.6 8-8V24a8 8 0 0 0-8-8zm4 56c0 2.206-1.794 4-4 4H24c-2.206 0-4-1.794-4-4V24c0-2.206 1.794-4 4-4h48c2.206 0 4 1.794 4 4v48zM48 27c-11.598 0-21 9.402-21 21s9.402 21 21 21 21-9.402 21-21-9.402-21-21-21zm0 38c-9.374 0-17-7.626-17-17s7.626-17 17-17 17 7.626 17 17-7.626 17-17 17z"/>,
		language: "RTR-01",
	},
	{
		id: "ProductImages-spr-01",
		type: "Product image",
		link: "/product-images/spr-01",
		languageCode: <path d="M48 2C22.595 2 2 22.595 2 48s20.595 46 46 46 46-20.595 46-46S73.405 2 48 2zm0 88C24.841 90 6 71.159 6 48S24.841 6 48 6s42 18.841 42 42-18.841 42-42 42zm0-80c-20.987 0-38 17.013-38 38s17.013 38 38 38 38-17.013 38-38-17.013-38-38-38zm4 71.757V76a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5.757c-9.939-1.171-18.578-6.641-24-14.5V54a2 2 0 0 0-2-2h-3.757c-.154-1.313-.243-2.646-.243-4s.089-2.687.243-4H18a2 2 0 0 0 2-2V28.743c5.422-7.859 14.061-13.329 24-14.5V20a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-5.757c9.939 1.171 18.578 6.641 24 14.5V42a2 2 0 0 0 2 2h3.757c.154 1.313.243 2.646.243 4s-.089 2.687-.243 4H78a2 2 0 0 0-2 2v13.257c-5.422 7.859-14.061 13.329-24 14.5zM38 48a5 5 0 1 1-10.001-.001A5 5 0 0 1 38 48zm30 0a5 5 0 1 1-10.001-.001A5 5 0 0 1 68 48z"/>,
		language: "SPR-01",
	},
	{
		id: "ProductImages-trm-01",
		type: "Product image",
		link: "/product-images/trm-01",
		languageCode: <path d="M86 4H10c-3.3 0-6 2.7-6 6v76a6 6 0 0 0 6 6h76c3.3 0 6-2.7 6-6V10a6 6 0 0 0-6-6zm2 82c0 1.084-.916 2-2 2H10c-1.103 0-2-.897-2-2V10c0-1.084.916-2 2-2h76c1.103 0 2 .897 2 2v76zM74 24H22c-3.3 0-6 2.7-6 6v36a6 6 0 0 0 6 6h52c3.3 0 6-2.7 6-6V30a6 6 0 0 0-6-6zm2 42c0 1.084-.916 2-2 2H22c-1.103 0-2-.897-2-2V30c0-1.084.916-2 2-2h52c1.103 0 2 .897 2 2v36zM56 80a2 2 0 0 1-2 2H42a2 2 0 0 1 0-4h12a2 2 0 0 1 2 2z"/>,
		language: "TRM-01",
	},
	{
		id: "ProductImages-vri-01",
		type: "Product image",
		link: "/product-images/vri-01",
		languageCode: <path d="M84 4H12c-4.4 0-8 3.6-8 8v72a8 8 0 0 0 8 8h72c4.4 0 8-3.6 8-8V12a8 8 0 0 0-8-8zm4 80c0 2.206-1.794 4-4 4H12c-2.206 0-4-1.794-4-4V12c0-2.206 1.794-4 4-4h72c2.206 0 4 1.794 4 4v72zM72 16H24c-4.4 0-8 3.6-8 8v48a8 8 0 0 0 8 8h48c4.4 0 8-3.6 8-8V24a8 8 0 0 0-8-8zm4 56c0 2.206-1.794 4-4 4H24c-2.206 0-4-1.794-4-4V24c0-2.206 1.794-4 4-4h48c2.206 0 4 1.794 4 4v48zM48 27c-11.598 0-21 9.402-21 21s9.402 21 21 21 21-9.402 21-21-9.402-21-21-21zm0 38c-9.374 0-17-7.626-17-17s7.626-17 17-17 17 7.626 17 17-7.626 17-17 17z"/>,
		language: "VRI-01",
	},
	{
		id: "ProductImages-vri-02",
		type: "Product image",
		link: "/product-images/vri-02",
		languageCode: <path d="M84 4H12c-4.4 0-8 3.6-8 8v72a8 8 0 0 0 8 8h72c4.4 0 8-3.6 8-8V12a8 8 0 0 0-8-8zm4 80c0 2.206-1.794 4-4 4H12c-2.206 0-4-1.794-4-4V12c0-2.206 1.794-4 4-4h72c2.206 0 4 1.794 4 4v72zM72 16H24c-4.4 0-8 3.6-8 8v48a8 8 0 0 0 8 8h48c4.4 0 8-3.6 8-8V24a8 8 0 0 0-8-8zm4 56c0 2.206-1.794 4-4 4H24c-2.206 0-4-1.794-4-4V24c0-2.206 1.794-4 4-4h48c2.206 0 4 1.794 4 4v48zM48 27c-11.598 0-21 9.402-21 21s9.402 21 21 21 21-9.402 21-21-9.402-21-21-21zm0 38c-9.374 0-17-7.626-17-17s7.626-17 17-17 17 7.626 17 17-7.626 17-17 17z"/>,
		language: "VRI-02",
	},
	{
		id: "ProductImages-vri-03",
		type: "Product image",
		link: "/product-images/vri-03",
		languageCode: <path d="M84 4H12c-4.4 0-8 3.6-8 8v72a8 8 0 0 0 8 8h72c4.4 0 8-3.6 8-8V12a8 8 0 0 0-8-8zm4 80c0 2.206-1.794 4-4 4H12c-2.206 0-4-1.794-4-4V12c0-2.206 1.794-4 4-4h72c2.206 0 4 1.794 4 4v72zM72 16H24c-4.4 0-8 3.6-8 8v48a8 8 0 0 0 8 8h48c4.4 0 8-3.6 8-8V24a8 8 0 0 0-8-8zm4 56c0 2.206-1.794 4-4 4H24c-2.206 0-4-1.794-4-4V24c0-2.206 1.794-4 4-4h48c2.206 0 4 1.794 4 4v48zM48 27c-11.598 0-21 9.402-21 21s9.402 21 21 21 21-9.402 21-21-9.402-21-21-21zm0 38c-9.374 0-17-7.626-17-17s7.626-17 17-17 17 7.626 17 17-7.626 17-17 17z"/>,
		language: "VRI-03",
	},
	{
		id: "ProductImages-wph-01",
		type: "Product image",
		link: "/product-images/wph-01",
		languageCode: <path d="M84 4H12c-4.4 0-8 3.6-8 8v72a8 8 0 0 0 8 8h72c4.4 0 8-3.6 8-8V12a8 8 0 0 0-8-8zm4 80c0 2.206-1.794 4-4 4H12c-2.206 0-4-1.794-4-4V12c0-2.206 1.794-4 4-4h72c2.206 0 4 1.794 4 4v72zM72 16H24c-4.4 0-8 3.6-8 8v48a8 8 0 0 0 8 8h48c4.4 0 8-3.6 8-8V24a8 8 0 0 0-8-8zm4 8v42H50V20h22c2.206 0 4 1.794 4 4zm-52-4h22v46H20V24c0-2.206 1.794-4 4-4zm-4 52v-2h26v6H24c-2.206 0-4-1.794-4-4zm52 4H50v-6h26v2c0 2.206-1.794 4-4 4z"/>,
		language: "WPH-01",
	},
	{
		id: "ProductImages-wrt-01",
		type: "Product image",
		link: "/product-images/wrt-01",
		languageCode: <path d="M84 4H12c-4.4 0-8 3.6-8 8v72a8 8 0 0 0 8 8h72c4.4 0 8-3.6 8-8V12a8 8 0 0 0-8-8zm4 80c0 2.206-1.794 4-4 4H12c-2.206 0-4-1.794-4-4V12c0-2.206 1.794-4 4-4h72c2.206 0 4 1.794 4 4v72zM72 16H24c-4.4 0-8 3.6-8 8v48a8 8 0 0 0 8 8h48c4.4 0 8-3.6 8-8V24a8 8 0 0 0-8-8zm4 56c0 2.206-1.794 4-4 4H24c-2.206 0-4-1.794-4-4V24c0-2.206 1.794-4 4-4h48c2.206 0 4 1.794 4 4v48zM48 27c-11.598 0-21 9.402-21 21s9.402 21 21 21 21-9.402 21-21-9.402-21-21-21zm0 38c-9.374 0-17-7.626-17-17s7.626-17 17-17 17 7.626 17 17-7.626 17-17 17z"/>,
		language: "WRT-01",
	},
	{
		id: "ProductImages-wms-01",
		type: "Product image",
		link: "/product-images/wms-01",
		languageCode: <path d="M59.2 1.4c-.346-.26-.767-.4-1.2-.4H48C35.344 1 32 13.428 32 20v56c0 9.138 6.882 19 18 19h8c.433 0 .854-.141 1.2-.4.802-.601 4.8-4.227 4.8-16.6V12c0-.286-.055-7.041-4.8-10.6zM36 71.702c2.906-.412 9.351-1.068 15.983-.03-.049 3.017-.233 9.382-.944 12.936-.406 2.026-6.337 2.7-10.51 2.423C37.629 84.107 36 79.97 36 76v-4.298zm24 6.297c-.003 4.267-.871 10.808-2.777 13.001H50c-.86 0-1.677-.097-2.47-.242 6.178-1.131 7.26-4.513 7.43-5.365C55.988 80.257 56 70.416 56 70a2 2 0 0 0-1.607-1.961C46.96 66.552 39.65 67.17 36 67.659V20.003C36.002 19.391 36.188 5 48 5h9.278C59.938 7.472 60 11.958 60 12v65.999zM21.923 92.55a2.002 2.002 0 0 1-2.472 1.373c-5.067-1.447-8.389-7.843-9.694-12.88-1.499-5.783-.815-10.813 1.829-13.457a2 2 0 1 1 2.828 2.828c-1.582 1.582-1.89 5.36-.785 9.625 1.307 5.044 4.217 9.266 6.92 10.038a2 2 0 0 1 1.374 2.473zm6.934-3.807a2.003 2.003 0 0 1-2.599 1.114c-2.92-1.169-5.428-4.592-6.709-9.158-1.248-4.45-.929-8.524.852-10.899a2.001 2.001 0 0 1 3.2 2.401c-.978 1.303-1.06 4.354-.2 7.419.904 3.225 2.649 5.847 4.343 6.523a2 2 0 0 1 1.113 2.6z"/>,
		language: "WMS-01",
	},
	{
		id: "ProductImages-win-01",
		type: "Product image",
		link: "/product-images/win-01",
		languageCode: <path d="M74 4H22C12.1 4 4 12.1 4 22v52c0 9.941 8.059 18 18 18h52c9.9 0 18-8.1 18-18V22c0-9.941-8.059-18-18-18Zm14 70c0 7.72-6.28 14-14 14H22c-7.72 0-14-6.28-14-14V22c0-7.72 6.28-14 14-14h52c7.72 0 14 6.28 14 14v52ZM77.5 58c-3.032 0-5.5 2.468-5.5 5.5s2.468 5.5 5.5 5.5 5.5-2.468 5.5-5.5-2.468-5.5-5.5-5.5Zm0 7c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5ZM81 76.594C81 78.472 79.472 80 77.594 80s-3.406-1.528-3.406-3.406 1.528-3.406 3.406-3.406S81 74.716 81 76.594Z"/>,
		language: "WIN-01",
	},
	{
		id: "ProductImages-ccl-01",
		type: "Product image",
		link: "/product-images/ccl-01",
		languageCode: <path d="M48 39c-13.255 0-24 6.492-24 14.5S34.745 68 48 68s24-6.492 24-14.5S61.255 39 48 39zm0 25c-11.786 0-20-5.534-20-10.5S36.214 43 48 43s20 5.534 20 10.5S59.786 64 48 64zm0-48C22.206 16 2 27.64 2 42.5v11C2 68.359 22.206 80 48 80s46-11.641 46-26.5v-11C94 27.64 73.794 16 48 16zm0 60C24.841 76 6 65.906 6 53.5S24.841 31 48 31s42 10.094 42 22.5S71.159 76 48 76zm0-49c-18.956 0-34.882 6.291-42 15.521V42.5C6 30.093 24.841 20 48 20s42 10.093 42 22.5v.021C82.882 33.291 66.956 27 48 27z"/>,
		language: "CCL-01",
	},
	{
		id: "ProductImages-out-02",
		type: "Product image",
		link: "/product-images/out-02",
		languageCode: <path d="M93.794 70.864c.027-.057.055-.112.077-.172.027-.072.045-.146.063-.22.01-.04.026-.076.033-.117l.003-.041c.012-.076.015-.152.018-.23.003-.058.008-.116.006-.174-.003-.061-.014-.122-.023-.183-.01-.073-.019-.146-.037-.217-.004-.014-.003-.029-.007-.043l-16-58a2 2 0 0 0-1.68-1.453c-16.435-2.054-36.49-2.026-43.797.062a2.001 2.001 0 0 0-1.07.749c-.073.101-7.403 10.246-14.627 23.199C6.964 51.579 2 65.702 2 76c0 3.008 2.636 6.501 22.187 9.601 10.215 1.619 20.564 2.387 20.667 2.395a2 2 0 0 0 2.133-1.775l.875-7.87 20.543-9.338c13.232 1.187 22.999 2.913 23.241 2.956.01.002.019.001.029.002.087.014.176.018.266.021.048.001.097.006.144.004.063-.003.126-.014.19-.023.074-.01.148-.02.219-.038.013-.003.026-.003.039-.007.038-.01.07-.029.107-.041.076-.026.152-.052.224-.087.052-.025.1-.055.149-.084a1.78 1.78 0 0 0 .18-.116 1.89 1.89 0 0 0 .136-.116c.051-.046.102-.092.148-.143.041-.045.076-.092.112-.14.041-.054.082-.108.117-.167.033-.054.061-.112.088-.17zM34.209 13.75c7.1-1.706 25.225-1.674 40.22.074l14.81 53.685c-12.499-1.993-58.621-8.312-82.687 2.502 3.639-21.608 24.455-51.729 27.657-56.261zM6 76c0-.421.026-.857.044-1.289a37.977 37.977 0 0 1 4.127-1.877c5.981 3.816 18.499 6.352 30.428 6.352 1.059 0 2.11-.025 3.154-.066l-.527 4.737C24.044 82.305 6.88 78.694 6 76zm39.517-.977c-11.571.774-23.287-1.247-29.945-3.942 12.935-3.479 29.913-3.597 44.551-2.697l-14.606 6.639z"/>,
		language: "OUT-02",
	},
];

export default LanguageCardData;